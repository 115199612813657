<template>
    <div class="deal">
        <SubHeader :title="'DEALS'" :link="'/'" :profile="true" :wish="true" />
        <!-- <ul class="p-3 pt-0 d-flex text-center fw-400">
            <li class="rounded-start py-3 w-50" :class="{'bg-yellow-1 fw-bold': page=='open' , 'bg-yellow-4 text-gray-500' : page !='open'}" @click="$router.push('/deal/list/open')">OPEN DEALS</li>

            <li class="rounded-end py-3 w-50" 
                :class="{'bg-yellow-1 fw-bold': page=='closed' , 'bg-yellow-4 text-gray-500' : page !='closed'}"
                @click="$router.push('/deal/list/closed')">CLOSED DEALS</li>
        </ul> -->
        <DealCate :idx="2"  @ChangeSort="ChangeSort"  />
        <!-- <List :list="list"></List> -->
        <ul class="p-3">
          <CloseListItem v-for="(item, index) in list" :key="index" :item="item" :index="index" class="mb-3" />
        </ul>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import List from '@/components/deal/List.vue'
import CloseListItem from '@/components/deal/CloseListItem.vue'
import DealCate from '@/components/deal/CategoryBtns.vue'
import Footer from '@/components/common/Footer.vue'
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    List,
    CloseListItem, Footer, SubHeader, DealCate
  },
  
  data(){
    return{
      list:[],
      sort : 'N',
      reward : '',
      buy : '',
    }
  },
  mounted(){
    this.GetDealList();
  },
  methods:{
    ChangeSort(item){
      this.reward = item.reward;
      this.buy = item.buy;
      this.sort = item.close_sort;
      this.GetDealList()
    },
    GetDealList(){
      const reward = this.reward;
      const buy = this.buy;
      const sort = this.sort;
      const body = {reward,buy,sort};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/deal/GetCloseDealList',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.list = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    }
  }
}
</script>